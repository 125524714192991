import "@/styles/globals.css";

import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import PlausibleProvider from "next-plausible";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import Head from "@/components/head";
import { env } from "@/env.client.mjs";
import { api } from "@/utils/api";

if (typeof window !== "undefined") {
	// checks that we are client-side
	posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
		api_host: env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
		loaded: (posthog) => {
			if (process.env.NODE_ENV === "development") posthog.debug();
		},
	});
}

function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
	return (
		<PostHogProvider client={posthog}>
			<PlausibleProvider domain="app.octolense.com">
				<SessionProvider session={session}>
					<Head />
					<Component {...pageProps} />
				</SessionProvider>
			</PlausibleProvider>
		</PostHogProvider>
	);
}

export default api.withTRPC(App);
