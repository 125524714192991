import NextHead from "next/head";

const Head = () => {
	return (
		<NextHead>
			<title>Octolense - GitHub Actions Dashboard</title>
			<link rel="icon" href="/favicon.ico" />
			<meta property="og:image" content="https://app.octolense.com/og.png" />
			<meta
				name="description"
				content="The ultimate GitHub Actions Dashboard for streamlined DevOps workflows"
			/>

			<meta
				name="description"
				content="Gain valuable insights and optimize your efficiency with Octolense, the GitHub Actions Dashboard for streamlined DevOps workflows"
			/>

			<meta property="og:url" content="https://app.octolense.com/" />
			<meta property="og:type" content="website" />
			<meta property="og:title" content="Octolense - GitHub Actions Dashboard" />
			<meta
				property="og:description"
				content="The ultimate GitHub Actions Dashboard for streamlined DevOps workflows"
			/>

			<meta name="twitter:card" content="summary_large_image" />
			<meta property="twitter:domain" content="app.octolense.com" />
			<meta property="twitter:url" content="https://app.octolense.com/" />
			<meta name="twitter:title" content="Octolense - GitHub Actions Dashboard" />
			<meta
				name="twitter:description"
				content="The ultimate GitHub Actions Dashboard for streamlined DevOps workflows"
			/>
			<meta name="twitter:image" content="https://app.octolense.com/og.png" />
		</NextHead>
	);
};

export default Head;
